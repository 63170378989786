import logo from './img.png';
import './App.css';
import './components/colors';
import Colors from "./components/colors";
import Colors1 from "./components/colors1";
import Colors2 from "./components/colors2";
import {useState} from "react";

function App() {
    const [design, setDesign] = useState(null)

    const handleDesign = (e) => {
        let val = e.target.value
        setDesign(val)
    }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="logo"/>
                <h2>Pick design type:</h2>
                <div style={{display: "flex"}}>
                    <button className={'button'} value={0} onClick={event => handleDesign(event)}>Design 0</button>
                    <button className={'button'} value={1} onClick={event => handleDesign(event)}>Design 1</button>
                    <button className={'button'} value={2} onClick={event => handleDesign(event)}>Design 2</button>
                </div>
                {design === '0' ? <Colors/> : design === '1' ? <Colors1/> : design === '2' ? <Colors2/> : null}
            </header>
        </div>
    );
}

export default App;
