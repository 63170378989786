import * as React from "react";
import {useEffect, useState} from "react";
import {ChromePicker} from 'react-color'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './colors.css';


const Colors2 = () => {
    const [modalBorderColor, setModalBorderColor] = useState('rgba(62, 78, 101, 1)')
    const [modalColor, setModalColor] = useState('rgba(62, 78, 101, 1)')
    const [title, setTitle] = useState('rgba(255, 255, 255, 1)')
    const [subTitle, setSubTitle] = useState('rgba(255, 255, 255, 1)')
    const [taskText, setTaskText] = useState('rgba(255, 255, 255, 1)')
    const [doneButtonBg, setDoneButtonBg] = useState('rgba(61, 80, 209, 1)')
    const [doneButtonText, setDoneButtonText] = useState('rgba(255, 255, 255, 1)')
    const [doneButtonBorder, setDoneButtonBorder] = useState('rgba(255, 255, 255, 0)')
    const [doneCircle, setDoneCircle] = useState('rgba(231, 231, 231, 1)')
    const [pageBackground, setPageBackground] = useState('rgba(20, 50, 88, 1)')

    const [copiedMessage, setCopiedMessage] = useState(false)

    const [BGC, setBGC] = useState('')


    const handleModalBorder = (color) => {
        const newArr = Object.values(color.rgb);
        setModalBorderColor('rgba' + '(' + newArr + ')')
    }
    const handleModalColor = (color1) => {
        const newArr = Object.values(color1.rgb);
        setModalColor('rgba' + '(' + newArr + ')')
    }
    const handleTitle = (color2) => {
        const newArr = Object.values(color2.rgb);
        setTitle('rgba' + '(' + newArr + ')')
    }
    const handleSubTitle = (color) => {
        const newArr = Object.values(color.rgb);
        setSubTitle('rgba' + '(' + newArr + ')')
    }
    const handleTask = (color) => {
        const newArr = Object.values(color.rgb);
        setTaskText('rgba' + '(' + newArr + ')')
    }
    const handleButtonBg = (color) => {
        const newArr = Object.values(color.rgb);
        setDoneButtonBg('rgba' + '(' + newArr + ')')
    }
    const handleButtonText = (color) => {
        const newArr = Object.values(color.rgb);
        setDoneButtonText('rgba' + '(' + newArr + ')')
    }
    const handleButtonBorder = (color) => {
        const newArr = Object.values(color.rgb);
        setDoneButtonBorder('rgba' + '(' + newArr + ')')
    }
    const handleBg = (color) => {
        const newArr = Object.values(color.rgb);
        setPageBackground('rgba' + '(' + newArr + ')')
    }

    let q = "`"
    let outputColor = [modalBorderColor, modalColor, title, subTitle, taskText, doneButtonBg, doneButtonText, doneButtonBorder, doneCircle, pageBackground];
    const addQs = (arr) => {
        let newArr = []
        for (let i = 0; i < arr.length; i++) {
            let x = arr[i]
            x = "'" + x + "'"
            newArr.push(x)
        }
        newArr = '[' + newArr + ']'
        return newArr
    }

    const copyText = () => {
        let modifiedColors = btoa(addQs(outputColor))
        console.log(modifiedColors)
    }

    const onCopy = () => {
        setCopiedMessage(true);
        setTimeout(() => {
            setCopiedMessage(false)
        }, 3000);
    }

    return (
        <div>
            <div
                id='color-output'
                style={{
                    width: '500px',
                    position: 'relative',
                    flexDirection: 'column',
                    display: 'flex',
                    padding: '20px 20px 20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '20px',
                    borderColor: 'white',
                    backgroundColor: 'lightgray',
                    margin: 'auto auto 20px auto',
                }}>
                <div
                    style={{
                        flexDirection: 'column',
                        width: '450px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        fontSize: '1rem',
                        borderRadius: '20px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '20px 20px 20px',
                    }}>
                    Edit the colors, then click copy and paste it in the FO "Incentive Colors" field. aaaaaa
                </div>
                <CopyToClipboard onCopy={onCopy} text={btoa(addQs(outputColor))}>
                    <button
                        style={{
                            fontSize: '18px',
                            height: '4vh',
                            width: '15vw',
                            background: 'grey',
                            color: 'black',
                            marginBottom: '0.5vh',
                            marginTop: '1vh',
                            borderRadius: '10px',
                            border: '0',
                            cursor: 'pointer',
                        }}>
                        CLICK TO COPY
                    </button>
                </CopyToClipboard>

                {copiedMessage && <div style={{color: 'black', fontSize: '1rem'}}>Copied!</div>}
            </div>
            <h6>Background color must be transparent if you have a custom background</h6>
            <h6>For ex: rgba(20, 50, 88, 0)</h6>
            {/*<label>Select background color </label>*/}
            {/*<input type="color" onChange={e => setBGC(e.target.value)}/>*/}
            <div style={{
                backgroundColor: `${pageBackground}`,
                borderRadius: '10px'
            }} id='test'>


                <div style={{
                    position: 'relative',
                    margin: 'auto',
                    width: '40vw',
                    flexDirection: 'column',
                    display: 'flex',
                    padding: '20px 20px 120px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '20px',
                    backgroundColor: `${modalColor}`,
                    border: '2px solid' + ' ' + `${modalBorderColor}`,
                    backdropFilter: 'blur(10px)',
                    minWidth: '30vw'
                }}><span style={{
                    color: `${title}`,
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 500,
                    padding: '10px 10px 0px',
                    fontSize: '30px',
                    textAlign: 'center'
                }}> Main Title</span><span style={{
                    color: `${subTitle}`,
                    fontWeight: 400,
                    fontFamily: 'Poppins, sans-serif',
                    padding: '5px 15px',
                    marginBottom: '25px',
                    textAlign: 'center',
                    fontSize: '17px'
                }}>To reach the target page, complete the following steps:</span>
                    <div style={{
                        width: '100%',
                        height: '1px',
                        background: 'rgba(0, 0, 0, 0.1)',
                        margin: '0px 0px 15px'
                    }}/>
                    <div className="3_886785590300044347_3" id={886785590300044347} style={{
                        color: 'black',
                        fontFamily: 'Poppins, sans-serif',
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        position: 'relative',
                        background: 'none',
                        width: '100%',
                        padding: '0px 5px 5px 0px',
                        fontSize: '23px',
                        margin: '14px 0px',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            flexDirection: 'column',
                            display: 'flex',
                            backgroundColor: `${doneButtonBg}`,
                            border: '1px solid' + ' ' + `${doneButtonBorder}`,
                            minWidth: 'calc(100% - 100px)',
                            padding: '16px 35px',
                            justifyContent: 'center'
                        }}><span style={{
                            marginRight: '20px',
                            color: `${taskText}`,
                            fontFamily: 'Poppins, sans-serif',
                            letterSpacing: '0.8px',
                            fontWeight: 600,
                            fontSize: '16px'
                        }}><img style={{
                            width: '20px',
                            top: '6px',
                            position: 'relative',
                            marginRight: '10px',
                            borderRadius: '50%',
                            padding: '2px'
                        }} src="https://d1wzdj81h1hubn.cloudfront.net/icons/eye.png"/>Read Suggested Articles</span>
                        </div>
                        <div className="sdfdsahps" style={{
                            position: 'absolute',
                            right: '6px',
                            bottom: 'initial',
                            transform: 'initial',
                            border: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'rgb(255, 255, 255)',
                            alignItems: 'center',
                            minWidth: '51px',
                            minHeight: '51px',
                            borderRadius: '50%',
                            lineHeight: '55px',
                            margin: '0px 15px'
                        }}>&nbsp;</div>
                    </div>
                    <div className="15_886785590300044383_15" id={886785590300044383} style={{
                        color: 'black',
                        fontFamily: 'Poppins, sans-serif',
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        position: 'relative',
                        background: 'none',

                        width: '100%',
                        padding: '0px 5px 5px 0px',
                        fontSize: '23px',
                        margin: '14px 0px',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            flexDirection: 'column',
                            display: 'flex',
                            backgroundColor: `${doneButtonBg}`,
                            border: '1px solid' + ' ' + `${doneButtonBorder}`,
                            minWidth: 'calc(100% - 100px)',
                            padding: '16px 35px',
                            justifyContent: 'center'
                        }}><span style={{
                            marginRight: '20px',
                            color: `${taskText}`,
                            fontFamily: 'Poppins, sans-serif',
                            letterSpacing: '0.8px',
                            fontWeight: 600,
                            fontSize: '16px'
                        }}><img style={{
                            width: '20px',
                            top: '6px',
                            position: 'relative',
                            marginRight: '10px',
                            borderRadius: '50%',
                            padding: '2px'
                        }} src="https://d1wzdj81h1hubn.cloudfront.net/icons/apps.png"/>Protect your PC with a free Antivirus</span>
                        </div>
                        <div className="sdfdsahps" style={{
                            position: 'absolute',
                            right: '6px',
                            bottom: 'initial',
                            transform: 'initial',
                            border: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'rgb(255, 255, 255)',
                            alignItems: 'center',
                            minWidth: '51px',
                            minHeight: '51px',
                            borderRadius: '50%',
                            lineHeight: '55px',
                            margin: '0px 15px'
                        }}>&nbsp;</div>
                    </div>
                    <div className="in-progress-bar" style={{
                        width: 'calc(100% - 35px)',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <div className="in-progress-bar-title" style={{
                            color: 'white',
                            fontFamily: 'Poppins, sans-serif',
                            letterSpacing: '0.8px',
                            fontWeight: 600,
                            fontSize: '16px',
                            padding: '10px 0 5px 0'
                        }}>Unlock Progress (<span className="in-progress-bar-title-value">0/2</span>)
                        </div>
                        <div className="in-progress-wrapper" style={{
                            width: '100%',
                            height: '20px',
                            color: `${doneButtonText}`,
                            backgroundColor: `${doneButtonBg}`,
                            border: '1px solid' + ' ' + `${doneButtonBorder}`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative'
                        }}>
                            <div className="in-bar in-bar-section0"
                                 style={{transition: 'all 2s', width: '50%', height: '100%'}}/>
                            <div className="in-bar in-bar-section1"
                                 style={{transition: 'all 2s', width: '50%', height: '100%'}}/>
                        </div>
                    </div>
                    <div style={{
                        width: 'calc(100% - 100px)',
                        textAlign: 'center',
                        lineHeight: '20px',
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 500,
                        marginTop: '10px',
                        padding: '10px',
                        cursor: 'pointer',
                        borderRadius: '0px',
                        position: 'absolute',
                        right: 'initial',
                        color: `${doneButtonText}`,
                        backgroundColor: `${doneButtonBg}`,
                        border: '1px solid' + ' ' + `${doneButtonBorder}`,
                        bottom: '60px',
                        fontSize: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}><span style={{
                        width: '25px',
                        height: '25px',
                        marginRight: '5px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}/> Unlock Content
                    </div>
                </div>
            </div>

            <div className='Color-Container'>
                <div className='color-item'>
                    <label className='color-title'>Rewarded Border Color</label>
                    <ChromePicker color={modalBorderColor} onChange={handleModalBorder}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Rewarded Background Color</label>
                    <ChromePicker color={modalColor} onChange={handleModalColor}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Main Title Color</label>
                    <ChromePicker color={title} onChange={handleTitle}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Sub Title Color</label>
                    <ChromePicker color={subTitle} onChange={handleSubTitle}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Tasks Color</label>
                    <ChromePicker color={taskText} onChange={handleTask}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Button Background Color</label>
                    <ChromePicker color={doneButtonBg} onChange={handleButtonBg}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Button Text Color</label>
                    <ChromePicker color={doneButtonText} onChange={handleButtonText}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Button Border Color</label>
                    <ChromePicker color={doneButtonBorder} onChange={handleButtonBorder}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Background Color </label>
                    <ChromePicker color={pageBackground} onChange={handleBg}/>
                </div>
            </div>
        </div>
    )
}
export default Colors2;