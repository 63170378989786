import * as React from "react";
import {useEffect, useState} from "react";
import {ChromePicker} from 'react-color'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './colors.css';



const Colors = () => {
    const [modalBorderColor, setModalBorderColor] = useState('rgba(167, 166, 169, 1)')
    const [modalColor, setModalColor] = useState('rgba(185, 184, 184, 0.6)')
    const [title, setTitle] = useState('rgba(255, 255, 255, 1)')
    const [subTitle, setSubTitle] = useState('rgba(255, 255, 255, 1)')
    const [taskText, setTaskText] = useState('rgba(0, 0, 0, 1)')
    const [doneButtonBg, setDoneButtonBg] = useState('rgba(255, 255, 255, 0)')
    const [doneButtonText, setDoneButtonText] = useState('rgba(255, 255, 255, 1)')
    const [doneButtonBorder, setDoneButtonBorder] = useState('rgba(255, 255, 255, 1)')
    const [doneCircle, setDoneCircle] = useState('rgba(231, 231, 231, 1)')

    const [copiedMessage, setCopiedMessage] = useState(false)

    const [BGC, setBGC] = useState('')


    const handleModalBorder = (color) => {
        const newArr = Object.values(color.rgb);
        setModalBorderColor('rgba' + '(' + newArr + ')')
    }
    const handleModalColor = (color1) => {
        const newArr = Object.values(color1.rgb);
        setModalColor('rgba' + '(' + newArr + ')')
    }
    const handleTitle = (color2) => {
        const newArr = Object.values(color2.rgb);
        setTitle('rgba' + '(' + newArr + ')')
    }
    const handleSubTitle = (color) => {
        const newArr = Object.values(color.rgb);
        setSubTitle('rgba' + '(' + newArr + ')')
    }
    const handleTask = (color) => {
        const newArr = Object.values(color.rgb);
        setTaskText('rgba' + '(' + newArr + ')')
    }
    const handleButtonBg = (color) => {
        const newArr = Object.values(color.rgb);
        setDoneButtonBg('rgba' + '(' + newArr + ')')
    }
    const handleButtonText = (color) => {
        const newArr = Object.values(color.rgb);
        setDoneButtonText('rgba' + '(' + newArr + ')')
    }
    const handleButtonBorder = (color) => {
        const newArr = Object.values(color.rgb);
        setDoneButtonBorder('rgba' + '(' + newArr + ')')
    }
    const handleCircle = (color) => {
        const newArr = Object.values(color.rgb);
        setDoneCircle('rgba' + '(' + newArr + ')')
    }

    let q = "`"
    let outputColor =  [ modalBorderColor ,modalColor, title, subTitle, taskText, doneButtonBg, doneButtonText, doneButtonBorder, doneCircle];
    const addQs = (arr) => {
        let newArr = []
        for(let i = 0; i < arr.length; i++) {
            let x = arr[i]
            x = "'" + x + "'"
            newArr.push(x)
        }
        newArr = '[' + newArr + ']'
        return newArr
    }

    const copyText = () => {
        let modifiedColors = btoa(addQs(outputColor))
        console.log(modifiedColors)
    }

    const onCopy = () => {
        setCopiedMessage(true);
        setTimeout(() => {
            setCopiedMessage(false)
        }, 3000);
    }

    return (
        <div>
            <div
                id='color-output'
                style={{
                    width: '500px',
                    position: 'relative',
                    flexDirection: 'column',
                    display: 'flex',
                    padding: '20px 20px 20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '20px',
                    borderColor: 'white',
                    backgroundColor: 'lightgray',
                    margin: 'auto auto 20px auto',
                }}>
                <div
                    style={{
                        flexDirection: 'column',
                        width: '450px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        fontSize: '1rem',
                        borderRadius: '20px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '20px 20px 20px',
                    }}>
                    Edit the colors, then click copy and paste it in the FO "Incentive Colors" field.
                </div>
                <CopyToClipboard onCopy={onCopy} text={btoa(addQs(outputColor))}>
                    <button
                        style={{
                            fontSize: '18px',
                        height: '4vh',
                        width: '15vw',
                        background: 'grey',
                        color: 'black',
                        marginBottom: '0.5vh',
                        marginTop: '1vh',
                        borderRadius: '10px',
                        border: '0',
                        cursor: 'pointer',
                    }}>
                        CLICK TO COPY</button>
                </CopyToClipboard>

                {copiedMessage && <div style={{color:'black',fontSize:'1rem'}}>Copied!</div>}
            </div>
            <label>Select background color </label>
            <input type="color" onChange={e => setBGC(e.target.value)}/>
            <div style={{
                backgroundColor: `${BGC}`,
                borderRadius: '10px'
            }} id='test'>
            <div
                //modal background + border color
                style={{
                    margin: 'auto',
                    width: '500px',
                    position: 'relative',
                    flexDirection: 'column',
                    display: 'flex',
                    padding: '20px 20px 120px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '20px',
                    backgroundColor: `${modalColor}`,
                    border: '2px solid' + ' ' + `${modalBorderColor}`,
                    backdropFilter: 'blur(10px), minWidth: 35vw'
                }}>
                <span
                    //title color
                    style={{
                        color: `${title}`,
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 500,
                        padding: '10px 10px 0px',
                        fontSize: '30px',
                        textAlign: 'center'
                    }}>Main Title</span>
                <span
                    style={{
                        color: `${subTitle}`,
                        fontWeight: 400,
                        fontFamily: 'Poppins, sans-serif',
                        padding: ' 5px 15px',
                        marginBottom: '25px',
                        textAlign: 'center',
                        fontSize: '17px'
                    }}>Sub Title</span>
                <div
                    style={{
                        color: 'black',
                        fontFamily: 'Poppins, sans-serif',
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        position: 'relative',
                        margin: '14px 0px',
                        background: 'none',
                        width: 'calc(90% - 80px)',
                        padding: '0px 35px 5px 0px',
                        fontSize: '23px',
                        borderRadius: '50px',
                        justifyContent: 'center'
                    }}>
                    <span
                        style={{
                            minWidth: '55px',
                            minHeight: '55px',
                            color: 'black',
                            fontWeight: 600,
                            fontSize: '23px',
                            fontFamily: 'Poppins, sans-serif',
                            position: 'relative',
                            backgroundColor: 'rgb(255, 255, 255)',
                            borderRadius: '50%',
                            lineHeight: '55px',
                            textAlign: 'center',
                            margin: '0px 15px',
                            padding: '0px'
                        }}>1</span>
                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            backgroundColor: 'rgb(255, 255, 255)',
                            minWidth: 'calc(100% - 100px)',
                            borderRadius: '50px',
                            padding: '5px 35px',
                            justifyContent: 'center',
                            border: '1px solid rgb(231, 231, 231)'
                        }}>
                    <span
                        style={{
                            marginRight: '20px',
                            color: `${taskText}`,
                            fontFamily: 'Poppins, sans-serif',
                            letterSpacing: '0.8px',
                            fontWeight: 600,
                            fontSize: '16px'
                        }}>Allow Notifications</span>
                        <span
                            style={{
                                marginRight: '10px',
                                fontSize: '13px',
                                fontFamily: 'Poppins sans-serif',
                                color: `${taskText}`,
                                whiteSpace: 'nowrap'
                            }}>Subscribe to push notifications</span>
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            right: 'initial',
                            bottom: 'initial',
                            border: '1px solid' + ' ' + `${doneCircle}`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: '55px',
                            minHeight: '55px',
                            borderRadius: '50%',
                            lineHeight: '55px',
                            margin: '0px 15px'
                        }}>
                    </div>
                </div>
                <div
                    style={{
                        color: 'black',
                        fontFamily: 'Poppins, sans-serif',
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        position: 'relative',
                        margin: '14px 0px',
                        background: 'none',
                        width: 'calc(90% - 80px)',
                        padding: '0px 35px 5px 0px',
                        fontSize: '23px',
                        borderRadius: '50px',
                        justifyContent: 'center'
                    }}>
                    <span
                        style={{
                            minWidth: '55px',
                            minHeight: '55px',
                            color: 'black',
                            fontWeight: 600,
                            fontSize: '23px',
                            fontFamily: 'Poppins, sans-serif',
                            position: 'relative',
                            backgroundColor: 'rgb(255, 255, 255)',
                            borderRadius: '50%',
                            lineHeight: '55px',
                            textAlign: 'center',
                            margin: '0px 15px',
                            padding: '0px'
                        }}>2</span>
                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            backgroundColor: 'rgb(255, 255, 255)',
                            minWidth: 'calc(100% - 100px)',
                            borderRadius: '50px',
                            padding: '5px 35px',
                            justifyContent: 'center',
                            border: '1px solid rgb(231, 231, 231)'
                        }}>
                        <span
                            style={{
                                marginRight: '20px',
                                color: `${taskText}`,
                                fontFamily: 'Poppins, sans-serif',
                                letterSpacing: '0.8px',
                                fontWeight: 600,
                                fontSize: '16px'
                            }}>Read suggested articles</span><span
                        style={{
                            marginRight: '10px',
                            fontSize: '13px',
                            fontFamily: 'Poppins, sans-serif',
                            color: `${taskText}`,
                            whiteSpace: 'nowrap'
                        }}>Click and Read one of the suggested articles</span>
                    </div>
                    <div className="sdfdsahps"
                         style={{
                             position: 'relative',
                             right: 'initial',
                             bottom: 'initial',
                             border: '1px solid' + ' ' + `${doneCircle}`,
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             minWidth: '55px',
                             minHeight: '55px',
                             borderRadius: '50%',
                             lineHeight: '55px',
                             margin: '0px 15px'
                         }}>
                    </div>
                </div>
                <div
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        lineHeight: '20px',
                        color: `${doneButtonText}`,
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 500,
                        marginTop: '10px',
                        padding: '10px',
                        cursor: 'pointer',
                        borderRadius: '50px',
                        border: '1px solid' + ' ' + `${doneButtonBorder}`,
                        position: 'absolute',
                        right: ' 7%',
                        backgroundColor: `${doneButtonBg}`,
                        bottom: '60px',
                        fontSize: '16px'
                    }}>Done
                </div>
            </div>
            </div>

            <div className='Color-Container'>
                <div className='color-item'>
                    <label className='color-title'>Rewarded Border Color</label>
                    <ChromePicker color={modalBorderColor} onChange={handleModalBorder}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Rewarded Background Color</label>
                    <ChromePicker color={modalColor} onChange={handleModalColor}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Main Title Color</label>
                    <ChromePicker color={title} onChange={handleTitle}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Sub Title Color</label>
                    <ChromePicker color={subTitle} onChange={handleSubTitle}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Tasks Color</label>
                    <ChromePicker color={taskText} onChange={handleTask}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Button Background Color</label>
                    <ChromePicker color={doneButtonBg} onChange={handleButtonBg}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Button Text Color</label>
                    <ChromePicker color={doneButtonText} onChange={handleButtonText}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Button Border Color</label>
                    <ChromePicker color={doneButtonBorder} onChange={handleButtonBorder}/>
                </div>
                <div className='color-item'>
                    <label className='color-title'>Task Circle Color</label>
                    <ChromePicker color={doneCircle} onChange={handleCircle}/>
                </div>
            </div>
        </div>
    )
}
export default Colors;